import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo';

const Pricing = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            appUrl
          }
        }
      }
    `
  );
  const { t } = useTranslation();

  return (
    <Layout noPadding>
      <Seo title="Pricing" path="/pricing" />
      <div className="items-center min-h-screen">
        <div className="font-semibold text-center">
          <h1 className="text-3xl md:text-5xl">{t('Flexible Plans')}</h1>
          <p className="px-8 w-full md:w-full text-xl font-normal text-gray-400">
            <Trans t={t}>
              Sign up and get a 14-day trial of Plus!
              <br />
              No credit card needed.
            </Trans>
          </p>
        </div>
        <div className="flex flex-wrap gap-8 justify-center items-stretch my-8 mx-4 md:mx-0">
          <div className="flex flex-col py-4 px-4 mt-6 w-full sm:w-1/2 md:w-1/2 lg:w-1/4 bg-white rounded-lg border border-gray-100 shadow-lg">
            <div className="sm:p-6 py-8 px-6">
              <div className="flex justify-center">
                <span className="inline-flex py-1 px-4 text-sm font-semibold tracking-wide leading-5 uppercase rounded-full">
                  Standard
                </span>
              </div>
              <div className="flex flex-col items-center mt-4 text-6xl font-extrabold leading-none">
                $15
                <span className="text-xs font-medium leading-8 text-gray-500">
                  {t('USD / month')}
                </span>
              </div>
            </div>
            <ul className="mb-auto w-full text-sm">
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Batch import')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Import templates and substitutions from other sources. CSV currently, more soon.'
                  )}
                </span>
              </li>
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Markdown support')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Use markdown syntax in your substitutions and templates.'
                  )}
                </span>
              </li>
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Generate copy in multiple formats')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Generate copy in plain text, markdown, HTML, or rich text.'
                  )}
                </span>
              </li>
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Character limit presets')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Use character limit presets for popular services to ensure your copy fits.'
                  )}
                </span>
              </li>
            </ul>
            <a
              className="py-3 px-3 mt-6 w-full text-center text-oxford-blue no-underline rounded-lg border border-oxford-blue shadow"
              href={`${site.siteMetadata.appUrl}`}
            >
              {t('Start free trial')}
            </a>
          </div>

          <div className="flex relative flex-col py-4 px-4 mt-6 w-full sm:w-1/2 md:w-1/2 lg:w-1/4 bg-white rounded-lg border border-gray-100 shadow-lg">
            <div className="block absolute -mt-8 -ml-4 w-full text-center">
              <span className="inline-block py-1 px-2 text-white bg-oxford-blue rounded-lg">
                {t('Popular')}
              </span>
            </div>
            <div className="sm:p-6 py-8 px-6">
              <div className="flex justify-center">
                <span className="inline-flex py-1 px-4 text-sm font-semibold tracking-wide leading-5 uppercase rounded-full">
                  Plus
                </span>
              </div>
              <div className="flex flex-col items-center mt-4 text-6xl font-extrabold leading-none">
                $29
                <span className="text-xs font-medium leading-8 text-gray-500">
                  {t('USD / month')}
                </span>
              </div>
            </div>
            <ul className="mb-auto w-full text-sm">
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Batch import')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Import templates and substitutions from other sources. CSV currently, more soon.'
                  )}
                </span>
              </li>
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Markdown support')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Use markdown syntax in your substitutions and templates.'
                  )}
                </span>
              </li>
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Generate copy in multiple formats')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Generate copy in plain text, markdown, HTML, or rich text.'
                  )}
                </span>
              </li>
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Character limit presets')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Use character limit presets for popular services to ensure your copy fits.'
                  )}
                </span>
              </li>
              <li className="group flex items-center mb-3">
                <span className="inline-block mx-auto border-b border-dotted cursor-pointer">
                  {t('Send to webhook')}
                </span>
                <span className="hidden group-hover:block absolute z-50 p-3 mt-16 text-gray-500 bg-white rounded shadow-lg">
                  {t(
                    'Use webhooks to publish copy to third-party and integration services.'
                  )}
                </span>
              </li>
            </ul>
            <a
              className="py-3 px-3 mt-6 w-full text-center text-white no-underline bg-oxford-blue rounded-lg shadow"
              href={`${site.siteMetadata.appUrl}`}
            >
              {t('Start free trial')}
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Pricing;
